
import { mapState, mapGetters } from 'vuex'
import GlobalMixins from '~/mixins/globalMixins'
import InitSocket from '~/mixins/initSocket'
import ServerSide from '~/mixins/serverSide'

export default {
    name: 'Theme2Layout',
    mixins: [GlobalMixins, InitSocket, ServerSide],
    data() {
        return {
            isMobileScreen: false,
        }
    },
    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: `https://${this.$config.DOMAIN}${this.$route.fullPath}`,
                },
            ],
            meta: [
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: `https://${this.$config.DOMAIN}${this.$route.fullPath}`, // This can be overridden by page-level meta tags.
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            languages: 'themes/settings/language',
            supportCountries: 'themes/settings/supportCountry',
            banks: 'banks/banks',
        }),
        ...mapState({
            menubars: (state) => state.cms.menus.menus,
            cms: (state) => state.cms.cms,
            isLogin: (state) => state.settings.isLogin,
            player: (state) => state.player.player,
            showHoldBalance: (state) => state.sockets.showHoldBalance,
            displayGetBonusDetail: (state) =>
                state.sockets.displayGetBonusDetail,
            displayGetBonusModalState: (state) =>
                state.sockets.displayGetBonusModal,
        }),
        displayGetBonusModal: {
            get() {
                return this.displayGetBonusModalState
            },
            set(value) {
                this.$store.commit('sockets/setDisplayGetBonusModal', value)
            },
        },
        navbarComponentName() {
            return !this.isMobileOnly()
                ? 'Theme2DesktopNavBar'
                : 'Theme2MobileNavBar'
        },
        footerComponentName() {
            return !this.isMobileOnly()
                ? 'Theme2DesktopFooter'
                : 'Theme2MobileFooterComponent'
        },
    },
    watch: {
        $route(to, from) {
            if (!this.$cookie.get('visit'))
                this.$store.dispatch('themes/settings/fetchVisitWebsite')
        },
    },
    created() {
        this.$vuetify.theme.dark = true
        if (process.client) {
            try {
                // eslint-disable-next-line nuxt/no-globals-in-created
                this.$cookie.set('previous_url', document.referrer)
            } catch (error) {}
        }
    },
	async mounted() {
		// Get invite and earn domain link (refer-friend page)
		this.$store.dispatch('player/getPlayerInviteAndEarnDomain')
        this.getFaviconIcon()
        // await this.$store.dispatch('themes/settings/whiteLabel')
        if (document.referrer)
            this.$cookie.set('previous_url', document.referrer)

        if (this.$cookie?.get('is_change_language'))
            this.$store.commit('settings/changeLanguageLoading', true)

        // Change default recaptcha language
        if (this.handleCookie('wl_locale'))
            this.$recaptcha.language = this.handleCookie('wl_locale')

        if (!window?.sessionStorage?.getItem('visit')) {
            this.$store.dispatch('themes/settings/fetchVisitWebsite')
            window.sessionStorage?.setItem('visit', 'true')
        }

        this.onResize()
        if (this.menubars.length <= 0)
            this.$store.dispatch('cms/menus/getMenubars')

        if (this.cms.length <= 0) this.$store.dispatch('cms/cms', {})

        await this.$store.dispatch('settings/supportCountryAndLanguage')
        // If the website is agent website, display agent floating icons
        if (!this.isMainWebsite)
            this.$store.dispatch(
                'themes/agent-floating-icon/agentFloatingIcons'
            )
        if (this.$cookie.get('token')) {
            this.initSocketTheme()
            await this.$store.dispatch(
                'themes/player-bank/getBankPlayerAccount'
            )
        }
        if (!this.languages.length) await this.setDefaultLanguages()
        await this.$store.dispatch('banks/accounts')
        if (this.isInternational) {
            if (this.$cookie.get('currency')) {
                await this.$store.dispatch(
                    'settings/operationSetting',
                    this.$cookie.get('currency')
                )
            }
        }
        if (!this.supportCountries.length)
            await this.$store.dispatch('themes/settings/getSupportCountry')
        // Check no request to API if website country support is only Thailand
        await this.$store.dispatch('themes/settings/getExchangeRate')
        await this.$store.commit('settings/changeLanguageLoading', false)
		this.$cookie.remove('is_change_language')
    },
    methods: {
        onResize() {
            if (process.browser) {
                const MOBILE_SCREEN_WIDTH = 1300
                this.isMobileScreen = window.innerWidth < MOBILE_SCREEN_WIDTH
                this.$store.commit(
                    'themes/settings/setIsMobile',
                    this.isMobileScreen
                )
                return null
            }
            this.isMobileScreen = false
            this.$store.commit(
                'themes/settings/setIsMobile',
                this.isMobileScreen
            )
        },
        closeHoldBalanceDialog() {
            this.$store.commit('sockets/setShowHoldBalance', false)
        },
    },
}
